<template>
  <v-card elevation="2" style="height:calc(100vh - 100px)">
    <v-card-text>Страница не найдена. Page not found. (NotFoundComponent)</v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>