<template>
  <div>
    <h1>{{ this.$route.meta.title_name }}</h1>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>