<template>
  <!-- Курс валют -->
  <v-card elevation="2" class="page__card pb-2 pt-3">
    <v-card-text class="pa-0">
      <v-card-title class="d-flex pa-0 mb-0">
        <router-link class="page__link size-14" :to="routeName">
          Курс валют
        </router-link>
        <!-- Переключение вида курса валют -->
        <v-btn-toggle class="ml-auto" v-model="toggle_exclusive" mandatory>
          <!-- 1 btn -->
          <v-btn
            class="toggle-btn px-0"
            min-width="30"
            height="30"
            :color="this.toggle_exclusive === 0 ? '#337AB7' : '#FFF'"
            style="opacity: 1"
            @click.prevent
          >
            <v-icon
              size="20"
              :color="this.toggle_exclusive === 0 ? '#FFF' : '#337AB7'"
            >mdi-menu
            </v-icon
            >
          </v-btn>
          <!-- 2 btn -->
          <v-btn
            class="toggle-btn px-0"
            min-width="30"
            height="30"
            :color="this.toggle_exclusive === 1 ? '#337AB7' : '#FFF'"
            style="opacity: 1"
            @click.prevent
          >
            <v-icon
              size="18"
              :color="this.toggle_exclusive === 1 ? '#FFF' : '#337AB7'"
            >mdi-finance
            </v-icon
            >
          </v-btn>
        </v-btn-toggle>
      </v-card-title>

      <!-- Таблица курса валют-->
      <v-data-table
        v-if="toggle_exclusive === 0"
        dense
        :headers="headers"
        :items="currency"
        hide-default-footer
        mobile-breakpoint="320"
        class="panel-data-table"
      >
        <template v-slot:item="{ item }">
          <tr style="height: 38px">
            <td class="px-1">{{ item.currency }}</td>
            <td class="px-1 text-right">
              <span class="d-flex align-center justify-end">
                <v-icon size="20" color="#D9534F">mdi-menu-down</v-icon>
                {{ item.selling }}
              </span>
            </td>
            <td class="px-1 text-right">
              <span class="d-flex align-center justify-end">
                <v-icon size="20" color="#5CB85C">mdi-menu-up</v-icon>
                {{ item.buy }}
              </span>
            </td>
            <td class="px-1 text-right">{{ item.nbu }}</td>
          </tr>
        </template>
      </v-data-table>
      <!-- Графики курса валют -->
      <BarChart
        v-if="toggle_exclusive === 1"
        :chartData="chartData"
        :options="options"
        @click.prevent
        style="height: 220px; width: 283px"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from '@/components/BarChart.vue'
import axios from "axios";

export default {
  props: {
    routeName: {
      type: String,
      required: true
    }
  },
  components: {BarChart},

  data() {
    return {
      toggle_exclusive: 0,
      headers: [
        {text: 'Валюта', align: 'start', value: 'currency', class: 'px-1 py-2', sortable: false},
        {text: 'Купівля', align: 'end', value: 'buy', class: 'px-1', sortable: false},
        {text: 'Продаж', align: 'end', value: 'selling', class: 'px-1', sortable: false},
        {text: 'НБУ', align: 'end', value: 'nbu', class: 'px-1', sortable: false}
      ],

      currency: [
        {currency: 'USD/UAH', buy: 0, selling: 0, nbu: 0},
        {currency: 'EUR/UAH', buy: 0, selling: 0, nbu: 0},
        {currency: 'RUB/UAH', buy: 0, selling: 0, nbu: 0}
      ],
      // график валют
      chartData: {
        labels: [1, 5, 10, 15, 20, 25, 30],
        datasets: [
          {
            label: 'Доллар',
            fill: true,
            lineTension: 0, // сглаженная линия или нет
            backgroundColor: 'rgba(51, 122, 183, 0.2)',
            borderColor: 'rgb(51, 122, 183)', // цвет линии синий
            pointBackgroundColor: 'rgb(51, 122, 183)', // цвет точки
            pointRadius: 3,
            data: [28.3, 28.35, 28.44, 28.5, 28.47, 28.3, 28.5]
          },
          {
            label: 'Евро',
            hidden: true, // не отображается по умолчанию
            fill: true, // заливка под линией
            lineTension: 0,
            backgroundColor: 'rgba(217, 83, 79, 0.2)',
            borderColor: 'rgb(217, 83, 79)',
            pointBackgroundColor: '#D9534F',
            pointRadius: 3,
            data: [30.3, 30.35, 30.4, 30.48, 30.5, 30.48, 30.46]
          },
          // {
          //   label: 'Рубль',
          //   hidden: true,
          //   fill: false,
          //   lineTension: 0,
          //   borderColor: 'green',
          //   pointBackgroundColor: 'green',
          //   pointRadius: 3,
          //   data: [ 0.38, 0.375, 0.376, 0.378, 0.379, 0.38, 0.375 ]
          // }
        ]
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },

  computed: {},

  methods: {
    /**
     * Получить курс валют из НБУ.
     */
    getNBU() {
      axios
        .get('https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json')
        .then(response => {
          this.currency[0].nbu = response.data.find(item => item.txt === 'Долар США').rate;
          this.currency[1].nbu = response.data.find(item => item.txt === 'Євро').rate;
          this.currency[2].nbu = response.data.find(item => item.txt === 'Російський рубль').rate;
        })
        .catch(error => {
          console.log(error);
        });
    },

    /**
     * Получить курс валют от ПриватБанка.
     */
    getPrivatBank() {
      axios
        .get('https://api.privatbank.ua/p24api/pubinfo?json&exchange&coursid=5')
        .then(response => {
          // "USD":
          this.currency[0].buy = response.data[0].sale;
          this.currency[0].selling = response.data[0].buy;

          // "EUR":
          this.currency[1].buy = response.data[1].sale;
          this.currency[1].selling = response.data[1].buy;

          // "RUR":
          this.currency[2].buy = response.data[2].sale;
          this.currency[2].selling = response.data[2].buy;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },

  created() {
    this.getNBU();
    this.getPrivatBank();
  }
}
</script>