import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '@/views/layouts/MainLayout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '/',
        name: 'home',
        meta: { title_name: 'Главная панель' },
        component: () => import('@/views/pages/Home/MainPanelPage')

      },
      {
        path: '/retail',
        name: 'retail',
        meta: { title_name: 'Розница' },
        component: () => import('@/views/pages/Retail/RetailPage')
      },
      {
        path: '/customer_orders',
        name: 'customer_orders',
        meta: { title_name: 'Заказы покупателей' },
        component: () => import('@/views/pages/CustomerOrder/CustomerOrdersPage')
      },
      {
        path: '/balance',
        name: 'balance',
        meta: { title_name: 'Остатки в кассах' },
        component: () => import('@/views/pages/Balance/Balance')
      },
      {
        path: '/credits',
        name: 'credits',
        meta: { title_name: 'Мы должны' },
        component: () => import('@/views/pages/Credits/Credits')
      },
      {
        path: '/debts',
        name: 'debts',
        meta: { title_name: 'Нам должны' },
        component: () => import('@/views/pages/Debts/Debts')
      },
    ]
  }, {
    path: '/',
    component: () => import('@/views/pages/Index'),
    children: [
      {
        name: 'Login',
        path: 'login',
        component: () => import('@/views/pages/Login'),
        // meta: {
        //   allow: ['GUEST_ROLE'],
        // },
      },
      {
        name: 'Register',
        path: 'register',
        component: () => import('@/views/pages/Register'),
        // meta: {
        //   allow: ['GUEST_ROLE'],
        // },
      },
      {
        name: 'Recovery',
        path: 'recovery',
        component: () => import('@/views/pages/RecoveryPassword'),
        // meta: {
        //   allow: ['GUEST_ROLE'],
        // },
      },
    ],
  },
  {
    path: '*',
    component: () => import('@/views/pages/NotFoundComponent'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
