<template>
    <v-card class="page-card elevation-2 d-flex flex-column">

        <!-- Вкладки -->
        <v-tabs class="mb-4 flex-grow-0">
            <v-tab class="px-3 flex-shrink-0">Все заказы (15)</v-tab>
            <v-tab class="px-3 flex-shrink-0">Текущие (9)</v-tab>
            <v-tab class="px-3 flex-shrink-0">Отгружено (6)</v-tab>
            <v-tab class="px-3 flex-shrink-0">Отменено (7)</v-tab>
            <div class="border flex-shrink-1"></div>
        </v-tabs>

        <!-- Таблица -->
        <div class="card-border">
            <v-data-table
                    class="page-table-data"
                    v-model="selected"

                    :headers="headers"

                    :items="orders"

                    :search="search"

                    item-key="id"

                    show-select
                    hide-default-footer
                    dense
                    :page.sync="page"
                    @page-count="pageCount = $event"
                    :items-per-page="itemsPerPage"
                    mobile-breakpoint="320"
            >

                <!-- Шапка таблицы -->
                <template v-slot:top>

                    <!-- Верхняя строка шапки -->
                    <div class="card-row top-row justify-end flex-wrap py-0">

                        <!-- Кнопка откр/закр "Расширенный поиск" -->
                        <v-btn
                                text
                                class="custom-button mr-auto my-1 px-0"
                                @click="isSearchOpen = !isSearchOpen"
                                style="color: #555"
                        >
                            <i class="search-icon mr-1"></i>
                            <span class="d-none d-md-inline-flex">Поиск</span>
                        </v-btn>
                        <!-- /Кнопка откр/закр "Расширенный поиск" -->

                        <code>дата: {{ date }}</code>

                        <!-- ВЧЕРА-СЕГОДНЯ -->
                        <v-btn-toggle class="d-none d-sm-inline-flex my-1">
                            <v-btn text class="custom-button px-1 px-md-3" @click="yesterdayDate()">Вчера</v-btn>
                            <v-btn text class="custom-button px-1 px-md-3" @click="todayDate()">Сегодня</v-btn>
                            <v-btn text class="custom-button px-1 px-md-3" @click="weekDate()">Неделя</v-btn>
                            <v-btn text class="custom-button px-1 px-md-3" @click="monthDate()">Месяц</v-btn>
                        </v-btn-toggle>
                        <!-- /ВЧЕРА-СЕГОДНЯ -->

                        <!-- Выпадающий календарь -->
                        <div class="my-1 ml-2">
                            <!--<date-picker-field>
                            </date-picker-field>-->
                            <date-picker
                                    v-model="date"
                                    range
                                    value-type="format"
                                    format="YYYY-MM-DD"
                            >
                            </date-picker>
                        </div>
                        <!-- /Выпадающий календарь -->

                    </div>
                    <!-- /Верхняя строка шапки -->

                    <!-- Расширенный поиск -->
                    <div v-if="isSearchOpen" class="card-row extended-search">
                        <v-container fluid class="px-0 py-0">


                            <!--<v-row align="center">
                                <v-select
                                        :items="authors"
                                        label="Author"
                                        @change="filterAuthor"
                                ></v-select>
                            </v-row>-->


                            <!-- Контрагент -->
                            <v-row align="center">
                                <v-subheader class="d-flex justify-end">Контрагент</v-subheader>
                                <v-text-field
                                        v-model="search"
                                        solo
                                ></v-text-field>
                            </v-row>

                            <!-- Источник -->
                            <v-row align="center">
                                <v-subheader class="d-flex justify-end">Источник</v-subheader>
                                <v-select
                                        :items="['Розница', 'Другое']"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        solo
                                        dense
                                        append-icon="mdi-chevron-down"
                                ></v-select>
                            </v-row>

                            <!-- Сумма -->
                            <v-row align="center">
                                <v-subheader class="d-flex justify-end">Сумма</v-subheader>
                                <v-text-field
                                        v-model="sum"
                                        solo
                                ></v-text-field>
                            </v-row>

                            <!-- Дата создания -->
                            <v-row align="center">
                                <v-subheader class="d-flex justify-end"
                                >Дата создания
                                </v-subheader
                                >
                                <date-picker-field
                                        icon="mdi-chevron-down"
                                        iconSize="20"
                                        style="display: none !important"
                                />

                                <date-picker
                                        v-model="date"
                                        range
                                        value-type="format"
                                        format="YYYY-MM-DD"
                                >
                                </date-picker>

                            </v-row>

                            <!-- Активен -->
                            <v-row align="center">
                                <v-subheader class="mb-1 d-flex justify-end">
                                    Активен
                                </v-subheader>
                                <v-select
                                        :items="['все', 'активные', 'неактивные']"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        solo
                                        dense
                                        append-icon="mdi-chevron-down"
                                ></v-select>
                            </v-row>

                            <!-- Кнопка Искать -->
                            <v-row align="center">
                                <v-btn
                                        text
                                        class="custom-button white--text px-3 my-1"
                                        style="background: #337ab7; margin-left: 120px"
                                >
                                    Искать
                                </v-btn>
                            </v-row>
                        </v-container>
                    </div>
                    <!-- /Расширенный поиск -->

                    <!-- Нижняя строка шапки -->
                    <div class="card-row flex-wrap justify-end py-0">
                        <span
                                class="d-none d-md-inline-flex my-1card-font-size font-weight-bold"
                        >
                        Всего: 150
                        </span>

                        <!-- Печать -->
                        <v-btn
                                text
                                class="custom-button custom-button--smaller print-button d-none d-md-inline ml-auto my-1"
                        >
                            <v-icon size="18" color="#337ab7">mdi-printer</v-icon>
                        </v-btn>

                        <!-- Создать на основании -->
                        <v-btn
                                text
                                class="custom-button custom-button--smaller white--text px-3 ml-auto ml-md-2 my-1"
                                style="background: #337ab7"
                        >
                            Создать на основании
                        </v-btn>

                        <!-- Добавить -->
                        <v-btn
                                text
                                class="custom-button custom-button--smaller white--text px-3 ml-2 my-1"
                                style="background: #5cb85c"
                        >
                            Добавить
                        </v-btn>

                        <!-- Действия -->
                        <v-menu offset-y nudge-bottom="3" rounded="4">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        text
                                        class="custom-button custom-button--smaller white--text pl-3 pr-0 ml-2 my-1"
                                        style="background: #f0ad4e"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    Действия
                                    <v-icon>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>

                            <v-list class="toolbar-menu">
                                <v-list-item dense v-for="n in 5" :key="n" @click="() => {}">
                                    <v-list-item-title>Option {{ n }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <!-- /Нижняя строка шапки -->

                </template>
                <!-- /Шапка таблицы -->

                <!-- Строка таблицы -->
                <!-- <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.id }}</td>
                    <td>{{ item.status }}</td>
                  </tr>
                </template> -->
                <!-- Колонки Таблицы -->
                <!-- Статус -->

                <template v-slot:item.status="{ item }">
                    <span
                            class="table-status-cell"
                            :class="defineCellColor(item.status)"
                    >
                        {{ item.status }}
                    </span>
                </template>

                <!-- Организация -->
                <template v-slot:item.company="{ item }">
                    <span
                            class="text-truncate link--text font-weight-bold d-inline-block"
                            style="width: 120px"
                    >
                    {{ item.company }}
                    </span>
                </template>

                <!-- Склад -->
                <template v-slot:item.storage="{ item }">
                    <span class="text-truncate d-inline-block" style="width: 100px">
                        {{ item.storage }}
                    </span>
                </template>

                <!-- Контрагент -->
                <template v-slot:item.counterparty="{ item }">
                    <span
                            class="text-truncate link--text font-weight-bold d-inline-block"
                            style="width: 120px"
                    >
                    {{ item.counterparty }}
                    </span>
                </template>

                <!-- Источник -->
                <template v-slot:item.source="{ item }">

                    <span class="text-truncate d-inline-block" style="width: 120px">
                        {{ item.source }}
                    </span>
                </template>
            </v-data-table>

            <!-- Pagination -->
            <div class="pagination-block">
                <span class="card-font-size">1 из 150</span>
                <v-pagination
                        v-model="page"
                        :length="pageCount"
                        :total-visible="totalVisiblePagination"
                        color="#3B7FBA"
                        class="mx-auto"
                ></v-pagination>

                <!-- <span class="row-options">
                  <label class="mr-2">Показывать строк</label>
                  <v-select
                    :items="rowsOptions"
                    v-model="rows"
                    dense
                    solo
                    @change="setRows"
                    class="rows-options-select"
                    item-color
                  ></v-select>
                </span> -->
            </div>
            <!-- /Pagination -->
        </div>
    </v-card>
</template>

<script>
    import DatePickerField from '@/components/DatePickerField'
    import DatePicker from 'vue2-datepicker';

    export default {
        components: {
            DatePickerField,
            DatePicker
        },

        data() {
            return {

                // дата календаря или диапазон дат:
                date: null,

                page: 1,
                pageCount: 3,
                itemsPerPage: 9,
                totalVisiblePagination: 4,
                // rowsOptions: [3, 5, 10],
                // rows: 5,
                isSearchOpen: false,
                singleSelect: false,
                selected: [],


                // поиск по разрешенным столбцам:
                search: null,
                // поиск по сумме:
                sum: null,


                // колонки основной таблицы (названия и характеристики):
                headers: [
                    {
                        text: '№',
                        sortable: false,
                        filterable: false,
                        value: 'id',
                        class: 'font-weight-bold'
                    },
                    {
                        text: 'Статус',
                        sortable: true,
                        filterable: false,
                        value: 'status',
                        class: 'font-weight-bold'
                    },
                    {
                        text: 'Дата и время',
                        sortable: false,
                        filterable: false,
                        value: 'time',
                        class: 'font-weight-bold'
                    },
                    {
                        text: 'Сумма',
                        sortable: true,
                        value: 'sum',
                        class: 'font-weight-bold',
                        filter: value => {
                            if (this.sum == null) return true;
                            let index = String(value).indexOf(this.sum);
                            if (index >= 0) return true
                        },
                    },
                    {
                        text: 'Организации',
                        sortable: true,
                        filterable: false,
                        value: 'company',
                        class: 'font-weight-bold'
                    },
                    {
                        text: 'Склад',
                        sortable: true,
                        filterable: false,
                        value: 'storage',
                        class: 'font-weight-bold'
                    },
                    {
                        text: 'Контрагент',
                        sortable: true,
                        value: 'counterparty',
                        class: 'font-weight-bold'
                    },
                    {
                        text: 'Источник',
                        sortable: true,
                        filterable: false,
                        value: 'source',
                        class: 'font-weight-bold'
                    },
                    {
                        text: 'Комментарий',
                        sortable: false,
                        filterable: false,
                        align: 'start',
                        value: 'comment',
                        class: 'font-weight-bold'
                    },
                ],

                // данные основной таблицы (заказы покупателей):
                orders: [
                    {
                        id: 1,
                        status: 'Отгружен',
                        time: '10.10.2020 14.58',
                        sum: 1500.0,
                        company: 'ИП Граблев',
                        storage: 'Янтарная',
                        counterparty: 'ИП Громов',
                        source: 'Интернет-магазин',
                        comment: '...'
                    },
                    {
                        id: 2,
                        status: 'Заказ',
                        time: '10.10.2020 14.58',
                        sum: 3000.0,
                        company: 'ИП Граблев',
                        storage: 'Янтарная',
                        counterparty: 'ИП Громов',
                        source: 'Интернет-магазин',
                        comment: '...'
                    },
                    {
                        id: 3,
                        status: 'Отменен',
                        time: '10.10.2020 14.58',
                        sum: 1500.0,
                        company: 'Компания',
                        storage: 'Название',
                        counterparty: 'Компания',
                        source: 'Интер',
                        comment: '...'
                    },
                    {
                        id: 4,
                        status: 'Отгружен',
                        time: '10.10.2020 14.58',
                        sum: 1500.0,
                        company: 'ИП Граблев',
                        storage: 'Ленина',
                        counterparty: 'ИП Громов',
                        source: 'Интернет-магазин',
                        comment: '...'
                    },
                    {
                        id: 5,
                        status: 'Отгружен',
                        time: '10.10.2020 14.58',
                        sum: 2500.0,
                        company: 'Компания',
                        storage: 'Янтарная',
                        counterparty: 'ИП Громов',
                        source: '',
                        comment: '...'
                    },
                    {
                        id: 6,
                        status: 'Отгружен',
                        time: '10.10.2020 14.58',
                        sum: 2500.0,
                        company: 'Компания',
                        storage: 'Янтарная',
                        counterparty: 'ИП Громов',
                        source: 'Интернет-магазин',
                        comment: '...'
                    },
                    {
                        id: 7,
                        status: 'Отгружен',
                        time: '10.10.2020 14.58',
                        sum: 2500.0,
                        company: 'ИП Граблев',
                        storage: 'Янтарная',
                        counterparty: 'ИП Громов',
                        source: 'Интернет-магазин',
                        comment: '...'
                    },
                    {
                        id: 8,
                        status: 'Отгружен',
                        time: '10.10.2020 14.58',
                        sum: 2500.0,
                        company: 'ИП Граблев',
                        storage: 'Янтарная',
                        counterparty: 'ИП Громов',
                        source: 'Интернет-магазин',
                        comment: '...'
                    },
                    {
                        id: 9,
                        status: 'Отгружен',
                        time: '10.10.2020 14.58',
                        sum: 2500.0,
                        company: 'ИП Граблев',
                        storage: 'Янтарная',
                        counterparty: 'ИП Громов',
                        source: 'Интернет-магазин',
                        comment: '...'
                    },
                    {
                        id: 10,
                        status: 'Отгружен',
                        time: '10.10.2020 14.58',
                        sum: 2500.0,
                        company: 'ИП Граблев',
                        storage: 'Ленина',
                        counterparty: 'ИП Громов',
                        source: 'Интернет-магазин',
                        comment: '...'
                    },
                    {
                        id: 11,
                        status: 'Отгружен',
                        time: '10.10.2020 14.58',
                        sum: 2500.0,
                        company: 'ИП Граблев',
                        storage: 'Янтарная',
                        counterparty: 'ИП Громов',
                        source: 'Интернет-магазин',
                        comment: '...'
                    },
                    {
                        id: 12,
                        status: 'Отгружен',
                        time: '10.10.2020 14.58',
                        sum: 2500.0,
                        company: 'ИП Граблев',
                        storage: 'Янтарная',
                        counterparty: 'ИП Громов',
                        source: 'Магазин',
                        comment: '...'
                    },
                    {
                        id: 13,
                        status: 'Отгружен',
                        time: '10.10.2020 14.58',
                        sum: 2500.0,
                        company: 'ИП Граблев',
                        storage: 'Янтарная',
                        counterparty: 'ИП Громов',
                        source: 'Интернет-магазин',
                        comment: '...'
                    },
                ],
            }
        },

        watch: {
            date(value) {
                console.log("date-watch: " + value);
            },
        },

        methods: {
            defineCellColor(status) {
                return (status === 'Отгружен') ? 'success-status' :
                    (status === 'Заказ' ? 'active-status' : 'error-status')
            },

            /*
            Усттановить сегодняшнюю дату
             */
            todayDate() {
                var date = new Date();
                this.date = [date.toISOString().substr(0, 10), date.toISOString().substr(0, 10)];
            },
            /*
            Установить вчерашнюю дату.
             */
            yesterdayDate() {
                var date = new Date();
                date.setDate(date.getDate() - 1);
                this.date = [date.toISOString().substr(0, 10), date.toISOString().substr(0, 10)];
            },
            /*
            Установить интервал последней недели.
             */
            weekDate() {
                var date = new Date();
                date.setDate(date.getDate() - 7);
                this.date = [date.toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)];
            },
            /*
            Установить интервал последнего месяца.
             */
            monthDate() {
                var date = new Date();
                date.setDate(date.getDate() - 30);
                this.date = [date.toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)];
            },
        },

        created() {
            // устанавливаем дату в календаре на сегодня:
            this.date = [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)];
        }
    }
</script>
