<template>
  <v-card class="auth-card">
    <v-card-title class="d-flex justify-center auth-card__title pt-5 pb-0 mb-5">
      Восстановление пароля
    </v-card-title>
    <v-card-text class="py-0">
      <!-- Email -->
      <div class="mb-5">
        <v-subheader class="auth-card--text px-0 mb-2">
          E-mail:
        </v-subheader>
        <v-text-field
          solo
          height="38"
          placeholder="Введите e-mail"
          required
        ></v-text-field>
      </div>
    </v-card-text>

    <v-card-actions class="d-flex flex-column pb-8 pt-0">
      <!-- Отправить пароль -->
      <v-btn
        class="auth__btn auth__btn--last"
        block
        dark
        color="#337AB7"
        height="38"
        depressed
        to="/"
      >
        Отправить пароль
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {

}
</script>