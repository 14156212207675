<template>
    <v-app-bar app fixed height="50" class="main-header pa-0">
        <v-btn class="burger-button toolbar-button" @click="drawer = !drawer">
            <v-icon> mdi-menu</v-icon>
        </v-btn>

        <!-- Загаловок в Хедере -->
        <v-toolbar-title class="text-truncate">{{ pageTitle }}</v-toolbar-title>

        <v-spacer/>

        <!-- Поле с датой/временем -->
        <v-sheet class="date-time-widget d-none d-sm-inline text-truncate">
            <v-icon class="mr-1"> mdi-clock-time-three-outline</v-icon>
            <span class="mr-1">14:39</span>
            <span class="mr-1">Чт</span>/
            <span class="mr-3">14.06</span>
        </v-sheet>
        <!-- Кнопка Full Screen -->
        <v-btn icon class="toolbar-button h-100 px-4">
            <v-icon
                    v-show="expand"
                    @click="toggleFullScreen('expand')"
                    size="23"
                    color="#337AB7"
            > mdi-arrow-expand
            </v-icon>
            <v-icon
                    v-show="collapse"
                    @click="toggleFullScreen('collapse')"
                    size="23"
                    color="#337AB7"
            >mdi-arrow-collapse
            </v-icon>
        </v-btn>
        <!-- Notifications -->
        <v-menu left offset-y nudge-bottom="3" rounded="4">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                        height="50"
                        class="toolbar-button px-4 pt-1"
                >
                    <v-badge content="3" color="#f0ad4e" overlap bordered>
                        <v-icon size="24" color="#337AB7"> mdi-bell-outline</v-icon>
                    </v-badge>
                </v-btn>
            </template>

            <v-list class="toolbar-menu">
                <v-list-item dense v-for="n in 3" :key="n" @click="() => {}">
                    <v-list-item-title>Notification {{ n }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <!-- Messages -->
        <v-menu left offset-y nudge-bottom="3" rounded="4">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                        height="50"
                        class="toolbar-button px-4 pt-1"
                >
                    <v-badge content="5" color="#f0ad4e" overlap bordered>
                        <v-icon size="24" color="#337AB7"> mdi-email-outline</v-icon>
                    </v-badge>
                </v-btn>
            </template>

            <v-list class="toolbar-menu">
                <v-list-item dense v-for="n in 5" :key="n" @click="() => {}">
                    <v-list-item-title>Message {{ n }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <!-- Auth -->
        <v-menu left offset-y nudge-bottom="3" rounded="4">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                        height="50"
                        class="toolbar-button pl-3 pr-1"
                >
                    <v-icon size="30" color="#C5C5C5"> mdi-account-circle</v-icon>
                    <v-icon>mdi-menu-down</v-icon>
                </v-btn>
            </template>

            <v-list class="toolbar-menu">
                <v-list-item dense v-for="(item, i) in authItems" :key="i">
                    <v-list-item-title>
                        <router-link :to="item.to">{{ item.title }}</router-link>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    export default {
        name: 'AppBar',

        data: function () {
            return {
                // возможность сделать Full Screen:
                expand: true,
                // возможность сделать обратное Full Screen:
                collapse: false,

                authItems: [
                    {
                        title: 'Логин',
                        to: '/login'
                    },
                    {
                        title: 'Регистрация',
                        to: '/register'
                    },
                    {
                        title: 'Восстановление пароля',
                        to: '/recovery'
                    }
                ]
            }
        },

        computed: {
            drawer: {
                get() {
                    return this.$store.state.drawer
                },
                set(val) {
                    this.$store.commit('SET_DRAWER', val)
                },
            },
            pageTitle() {
                return this.$route.meta.title_name
            }
        },

        methods: {
            /*
            Разворот на весь экран (Full Screen)
             */
            toggleFullScreen(state) {
                let elem = document.querySelector('html');
                if (
                    (document.fullScreenElement !== undefined && document.fullScreenElement === null) ||
                    (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
                    if (elem.requestFullScreen) {
                        elem.requestFullScreen()
                    } else if (elem.mozRequestFullScreen) {
                        elem.mozRequestFullScreen()
                    } else if (elem.webkitRequestFullScreen) {
                        elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
                    } else if (elem.msRequestFullscreen) {
                        elem.msRequestFullscreen()
                    }
                } else {
                    if (document.cancelFullScreen) {
                        document.cancelFullScreen()
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen()
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen()
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen()
                    }
                }
                if (state === 'expand') {
                    this.collapse = true;
                    this.expand = false
                } else {
                    this.collapse = false;
                    this.expand = true
                }
            },
        }
    }
</script>
