<template>
  <v-card class="auth-card">
    <v-card-title
      class="d-flex justify-center auth-card__title px-7 pt-5 pb-0 mb-5"
    >
      Авторизация
    </v-card-title>

    <v-card-text class="py-0">
      <!-- Email -->
      <div class="mb-4">
        <v-subheader class="auth-card--text px-0 mb-2"> E-mail: </v-subheader>
        <v-text-field
          solo
          height="38"
          placeholder="Введите e-mail"
          required
        ></v-text-field>
      </div>
      <!-- Password -->
      <div class="mb-5">
        <v-subheader class="auth-card--text px-0 mb-2"> Пароль: </v-subheader>
        <v-text-field
          solo
          placeholder="Введите пароль"
          height="38"
          type="password"
          required
        ></v-text-field>
      </div>
      <!-- Запомнить меня -->
      <div class="mb-5 d-flex">
        <label class="d-flex align-center">
          <input type="checkbox" class="mr-2" />
          <span class="auth-card--text"> Запомнить </span>
        </label>
        <!-- Забыли пароль -->
        <router-link to="/recovery" class="auth-card__link ml-auto"
          >Забыли пароль?</router-link
        >
      </div>
    </v-card-text>

    <v-card-actions class="d-flex flex-column pb-8 pt-0">
      <!-- Войти -->
      <v-btn
        class="auth__btn"
        block
        dark
        color="#337AB7"
        height="38"
        depressed
        to="/"
      >
        Войти
      </v-btn>
      <!-- Зарегистрироваться -->
      <v-btn
        class="auth__btn auth__btn--last ml-0"
        block
        height="38"
        color="#333"
        depressed
        outlined
        to="/register"
      >
        Зарегистрироваться
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {

}
</script>