<template>
  <div class="position-relative d-inline-block">
    <date-picker
      v-model="rangeDate"
      type="date"
      range
      format="DD.MM.YYYY"
      range-separator=" - "
      confirm
      confirm-text="Применить"
      :open.sync="open"
    >
      <template v-slot:footer>
        <span class="mx-footer-text">{{ rangeDateText }}</span>
        <v-btn
          @click="open = false"
          class="cancel-button"
          outlined
        >Отмена</v-btn>
      </template>
    </date-picker>
    <!-- Иконка справа -->
    <v-icon class="menu-icon" :size="iconSize">{{ icon }}</v-icon>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
  components: { DatePicker },
  data() {
    return {
      open: false,
      rangeDate: [new Date(), new Date()],
    };
  },

  props: {
    icon: {
      type: String,
      default: 'mdi-menu-down'
    },
    iconSize: {
      type: [ String, Number ],
      default: 24
    }
  },

  computed: {
    rangeDateText() {
      return this.rangeDate.map(el => el.toISOString().substring(0, 10))
        .map(el => `${el.slice(8)}-${el.slice(5, 7)}-${el.slice(0, 4)}`)
        .sort()
        .join(' - ');
    }
  }
};
</script>

<style lang="scss">

.mx-datepicker-range {
  width: 200px;
}

.mx-input {
  position: relative;
  height: 32px !important;
  font-size: 12px;
  border-color: rgba(0, 0, 0, 0.12);
  box-shadow: none;
  transition: 0.3s;
  text-align: right;
  padding-left: 30px;
  padding-right: 30px;
}

.mx-icon-calendar {
  left: 8px;
  display: inline-block !important;
  width: 16px;
  pointer-events: none;
}

.mx-input-wrapper:hover .mx-icon-clear {
  display: none;
}

.menu-icon {
  position: absolute !important;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.mx-btn-icon-double-left {
  display: none;
}

.mx-btn-icon-double-right {
  display: none;
}

.mx-calendar + .mx-calendar {
  border-left: none;
}

.mx-datepicker-main {
  border-radius: 4px !important;
  color: #555;
}

.mx-calendar-header button {
  color: inherit;
  font-weight: bold;
}

.mx-datepicker-btn-confirm {
  text-transform: none;
  font-size: 12px;
  letter-spacing: 0.5px;
  height: 28px;
  padding: 4px 8px;
  font-weight: 400;
  color: #fff;
  background: #337ab7;

  &:hover {
    color: #fff;
  }
}

.cancel-button {
  text-transform: none !important;
  font-size: 12px !important;
  letter-spacing: 0.5px !important;
  height: 28px !important;
  padding: 4px 8px;
  font-weight: 400 !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  padding: 4px 8px !important;
}

.mx-calendar-content .cell.active {
  background: #337ab7;
}

.mx-table-date .today {
  color: #337ab7;
}

.mx-footer-text {
  margin-right: 16px;
  font-size: 12px;

}

.mx-table thead th {
  font-weight: bold !important;
}

.mx-input:hover, .mx-input:focus {
  border-color: #2196F3;
}

</style>