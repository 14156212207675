<template>
  <!-- Нам должны -->
  <div class="page__card-wrap">
    <v-card
      elevation="2"
      class="page__card"
    >
      <v-card-text class="pa-0">
        <v-card-title class="pa-0 my-1">
          <router-link class="page__link body-1" :to="routeName">
            Нам должны
          </router-link>
        </v-card-title>
        <v-simple-table class="panel-simple-table">
          <template v-slot:default>
            <tbody>
              <!-- Текущая задолженность -->
              <tr>
                <td class="px-0">Текущая задолженность</td>
                <td class="px-0 text-right pl-2">
                  {{ currentDebt.toFixed(2).split(".").join(",") }}
                </td>
              </tr>
              <!-- Просрочка дней -->
              <tr>
                <td class="px-0" colspan="2">
                  <span class="d-block my-1 font-weight-bold red-title">
                    Просрочка дней
                  </span>
                </td>
              </tr>
              <tr v-for="item in debtGeneral" :key="item.id">
                <td class="px-0">{{ item.text }}</td>
                <td class="px-0 text-right pl-2">
                  {{ item.sum.toFixed(2).split(".").join(",") }}
                </td>
              </tr>
              <!-- Просроченная задолженность -->
              <tr>
                <td class="px-0">
                  <span class="d-block my-1 font-weight-bold red-title">
                    Просроченно итого
                  </span>
                </td>
                <td class="px-0 pl-2">
                  <span class="d-block font-weight-bold red-title text-right">
                    {{
                      debtGeneral
                        .reduce((acum, item) => acum + item.sum, 0)
                        .toFixed(2)
                        .split(".")
                        .join(",")
                    }}
                  </span>
                </td>
              </tr>
              <!-- Итого задолженность -->
              <tr>
                <td class="px-0">
                  <span class="d-block my-1"> Итого задолженность </span>
                </td>
                <td class="px-0 pl-2">
                  <span class="d-block font-weight-bold text-right">
                    {{
                      debtGeneral
                        .reduce((acum, item) => acum + item.sum, 0)
                        .toFixed(2)
                        .split(".")
                        .join(",")
                    }}
                  </span>
                </td>
              </tr>
              <!-- Топ должников -->
              <tr>
                <td class="px-0" colspan="2">
                  <span class="d-flex total-cell">
                    ТОП 5 должников
                    <span
                      class="d-block text-right ml-auto font-weight-bold pl-2"
                    >
                      {{
                        debtTop
                          .reduce((acum, item) => acum + item.sum, 0)
                          .toFixed(2)
                          .split(".")
                          .join(",")
                      }}
                    </span>
                  </span>
                </td>
              </tr>
              <tr v-for="item in debtTop" :key="item.id">
                <td class="px-0">{{ item.name }}</td>
                <td class="px-0 text-right pl-2">
                  {{ item.sum.toFixed(2).split(".").join(",") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    routeName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // текущая задолженность НАМ
      currentDebt: 4000,

      // просрочка дней
      debtGeneral: [
        {
          id: 0,
          text: '0-10 дней',
          sum: 0
        }, {
          id: 1,
          text: '11-30 дней',
          sum: 0
        }, {
          id: 2,
          text: '31-60 дней',
          sum: 0
        }, {
          id: 3,
          text: '61-90 дней',
          sum: 0
        }, {
          id: 4,
          text: 'Больше 90 дней',
          sum: 1400
        },
      ],
      // Топ должников
      debtTop: [
        {
          id: 100,
          name: 'Иванов',
          sum: 7000
        }, {
          id: 101,
          name: 'Петров',
          sum: 1000
        }, {
          id: 102,
          name: 'Сидоров',
          sum: 500
        }, {
          id: 103,
          name: 'Федоров',
          sum: 400
        }, {
          id: 104,
          name: 'Сергеев',
          sum: 100
        }
      ]
    }
  }
}
</script>