<template>
  <v-app>
    <v-app-bar fixed height="50" class="index-header pa-0" color="#334659" flat>
      <v-container class="d-flex align-center py-0 h-100">
        <!-- Logo -->
        <div class="logo">
          <img src="@/assets/img/big-logo.svg" width="156" />
        </div>
        <v-spacer />
        <!-- Регистрация -->
        <v-btn
          class="index-header__btn px-4"
          dark
          color="#334659"
          height="100%"
          depressed
          to="/register"
        >
          <v-icon class="mr-3" size="23">mdi-account-plus-outline</v-icon>
          Регистрация
        </v-btn>
        <!-- Вход -->
        <v-btn
          class="index-header__btn px-4"
          dark
          color="#334659"
          height="100%"
          depressed
          to="/"
        >
          <v-icon class="mr-3" size="22">mdi-exit-to-app</v-icon>
          Вход
        </v-btn>
      </v-container>
    </v-app-bar>

    <v-main class="index-content">
      <v-container class="d-flex align-center justify-center px-4 py-2">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {

}
</script>