<template>
  <v-row class="page__row main-panel">
    <panel-balance routeName="/balance" />
    <panel-debts routeName="/debts" />
    <panel-credits routeName="/credits" />

    <div class="page__card-wrap flex-grow-0">
      <panel-finance-move routeName="/finance_move" />
      <panel-exchange-rate
        routeName="/exchange_route"
        class="mt-4"
      />
    </div>
  </v-row>
</template>

<script>
import PanelBalance from './components/PanelBalance'
import PanelDebts from './components/PanelDebts'
import PanelCredits from './components/PanelCredits'
import PanelFinanceMove from './components/PanelFinanceMove'
import PanelExchangeRate from './components/PanelExchangeRate'

export default {
  components: {
    PanelBalance,
    PanelDebts,
    PanelCredits,
    PanelFinanceMove,
    PanelExchangeRate
  },
}
</script>