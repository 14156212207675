<template>
  <v-navigation-drawer
    app
    touchless
    v-model="drawer"
    :mini-variant.sync="drawer"
    mini-variant-width="50"
    width="240"
    permanent
    color="#3E5A70"
    dark
    :class="{ 'drawer--closed': drawer }"
    style="opacity: 1"
  >
    <div class="logo">
      <div class="logo__wrap" v-show="!drawer">
        <img
          src="@/assets/img/big-logo.svg" width="156"
        />
      </div>

      <div class="small-logo__wrap" v-show="drawer">
        <img
          src="@/assets/img/small-logo.svg" width="26"
        />
      </div>
    </div>
    <!-- Вкладки Бокового Меню -->
    <v-list class="py-0">
      <template v-for="(item, i) in drawerItems">
        <!-- Без подпунктов -->
        <v-list-item
          v-if="!item.children"
          :key="i"
          link
          class="drawer-text"
          :to="item.to"
        >
          <v-list-item-icon
            class="justify-center my-auto"
            :class="{ 'mr-2': !drawer }"
            style="min-width: auto"
          >
            <!-- Иконка 1 -->
            <v-icon size="18">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="drawer-text py-0">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- С подпунктами -->
        <v-list-group v-if="item.children" :key="i" class="drawer-text">
          <template v-slot:appendIcon>
            <v-icon size="20">mdi-chevron-down</v-icon>
          </template>
          <!-- Вкладка -->
          <template v-slot:activator>
            <v-list-item-icon
              class="justify-center my-auto"
              :class="{ 'mr-2': !drawer }"
              style="min-width: auto"
            >
              <!-- Иконка 2 -->
              <v-icon size="18">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title class="drawer-text py-0">
              {{ item.title }}
            </v-list-item-title>
          </template>
          <!-- Подпункты-->
          <v-list class="drawer-submenu py-0" v-if="!drawer">
            <v-list-item
              v-for="(subitem, i) in item.children"
              :key="i"
              :to="subitem.to"
              link
              class="pl-11"
            >
              <v-list-item-title class="drawer-text">
                {{ subitem.subtitle }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <!-- /Подпункты-->
        </v-list-group>
      </template>
    </v-list>
    <!-- /Вкладки Бокового Меню -->
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'AppDrawer',

  data: () => ({
    activeListGroup: true,
    drawerItems: [
      { title: 'Главная панель', icon: 'mdi-view-agenda-outline', to: '/' },
      { title: 'Розница', icon: 'mdi-city-variant-outline', to: '/retail' },
      { title: 'Закупки', icon: 'mdi-cart-outline' },
      {
        title: 'Продажи', icon: 'mdi-shopping-outline', id: 'sells',
        children: [
          { subtitle: 'Заказы покупателей', to: '/customer_orders', dataId: 'sells' }
        ]
      },

      { title: 'Склады', icon: 'mdi-dolly', to: '' },
      {
        title: 'Финансы', icon: 'mdi-currency-usd',
        children: [
          { subtitle: 'Финансы 1' }, { subtitle: 'Финансы 2' }
        ]
      },
      { title: 'Пользователи', icon: 'mdi-account-supervisor-outline', to: '' },
      { title: 'Контрагенты', icon: 'mdi-layers-outline', to: '' },
      { title: 'Номенклатура', icon: 'mdi-file-check-outline', to: '' },
      { title: 'Задолженность', icon: 'mdi-bookmark-minus-outline', to: '' },
      { title: 'Взаиморасчёты', icon: 'mdi-compare-vertical', to: '' },
      { title: 'Отчёты', icon: 'mdi-finance', to: '' },
    ],
  }),

  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
  },

  methods: {}

}

</script>