<template>
  <v-app>
    <core-app-drawer />
    <core-app-bar />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>


<script>
import CoreAppBar from '@/views/layouts/components/core/AppBar'
import CoreAppDrawer from '@/views/layouts/components/core/AppDrawer'

export default {
  name: 'MainLayout',
  components: {
    CoreAppBar, CoreAppDrawer
  }
}
</script>