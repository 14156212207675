var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"main-header pa-0",attrs:{"app":"","fixed":"","height":"50"}},[_c('v-btn',{staticClass:"burger-button toolbar-button",on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v(" mdi-menu")])],1),_c('v-toolbar-title',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),_c('v-sheet',{staticClass:"date-time-widget d-none d-sm-inline text-truncate"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-clock-time-three-outline")]),_c('span',{staticClass:"mr-1"},[_vm._v("14:39")]),_c('span',{staticClass:"mr-1"},[_vm._v("Чт")]),_vm._v("/ "),_c('span',{staticClass:"mr-3"},[_vm._v("14.06")])],1),_c('v-btn',{staticClass:"toolbar-button h-100 px-4",attrs:{"icon":""}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.expand),expression:"expand"}],attrs:{"size":"23","color":"#337AB7"},on:{"click":function($event){return _vm.toggleFullScreen('expand')}}},[_vm._v(" mdi-arrow-expand ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapse),expression:"collapse"}],attrs:{"size":"23","color":"#337AB7"},on:{"click":function($event){return _vm.toggleFullScreen('collapse')}}},[_vm._v("mdi-arrow-collapse ")])],1),_c('v-menu',{attrs:{"left":"","offset-y":"","nudge-bottom":"3","rounded":"4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"toolbar-button px-4 pt-1",attrs:{"text":"","height":"50"}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"content":"3","color":"#f0ad4e","overlap":"","bordered":""}},[_c('v-icon',{attrs:{"size":"24","color":"#337AB7"}},[_vm._v(" mdi-bell-outline")])],1)],1)]}}])},[_c('v-list',{staticClass:"toolbar-menu"},_vm._l((3),function(n){return _c('v-list-item',{key:n,attrs:{"dense":""},on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v("Notification "+_vm._s(n))])],1)}),1)],1),_c('v-menu',{attrs:{"left":"","offset-y":"","nudge-bottom":"3","rounded":"4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"toolbar-button px-4 pt-1",attrs:{"text":"","height":"50"}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"content":"5","color":"#f0ad4e","overlap":"","bordered":""}},[_c('v-icon',{attrs:{"size":"24","color":"#337AB7"}},[_vm._v(" mdi-email-outline")])],1)],1)]}}])},[_c('v-list',{staticClass:"toolbar-menu"},_vm._l((5),function(n){return _c('v-list-item',{key:n,attrs:{"dense":""},on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v("Message "+_vm._s(n))])],1)}),1)],1),_c('v-menu',{attrs:{"left":"","offset-y":"","nudge-bottom":"3","rounded":"4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"toolbar-button pl-3 pr-1",attrs:{"text":"","height":"50"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30","color":"#C5C5C5"}},[_vm._v(" mdi-account-circle")]),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{staticClass:"toolbar-menu"},_vm._l((_vm.authItems),function(item,i){return _c('v-list-item',{key:i,attrs:{"dense":""}},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":item.to}},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }