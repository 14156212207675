<template>
  <!-- Движение средств выбранных счетов -->
  <v-card elevation="2" class="page__card pb-1">
    <v-card-text class="pa-0">
      <v-card-title class="pa-0 my-1">
        <router-link class="page__link size-14" :to="routeName">
          Движение средств выбранных счетов
        </router-link>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="financeMovement"
        hide-default-footer
        mobile-breakpoint="320"
        class="panel-data-table"
      >
        <template v-slot:item="{ item }">
          <tr style="height: 38px">
            <td class="px-1">{{ item.currency }}</td>
            <td class="px-1 text-right">
              <span class="d-flex align-center justify-end">
                <v-icon size="20" color="#D9534F">mdi-menu-down</v-icon>
                {{ item.income }}
              </span>
            </td>
            <td class="px-1 text-right">
              <span class="d-flex align-center justify-end">
                <v-icon size="20" color="#5CB85C">mdi-menu-up</v-icon>
                {{ item.outgo }}
              </span>
            </td>
            <td class="px-1 text-right font-weight-bold">
              {{ item.availableSum }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    routeName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      headers: [
        { text: 'Валюта', align: 'start', value: 'currency', class: 'px-1 py-2', sortable: false },
        { text: 'Пост-ния', align: 'end', value: 'income', class: 'px-1', sortable: false },
        { text: 'Списания', align: 'end', value: 'outgo', class: 'px-1', sortable: false },
        { text: 'Дост. сумма', align: 'end', value: 'availableSum', class: 'font-weight-bold pl-2 pr-1', sortable: false }
      ],

      financeMovement: [
        { currency: 'UAH', income: 1055000, outgo: 1055000, availableSum: 1055000 },
        { currency: 'USD', income: 0, outgo: 0, availableSum: 0 },
        { currency: 'EUR', income: 0, outgo: 0, availableSum: 0 },
        { currency: 'RUB', income: 0, outgo: 0, availableSum: 0 },
      ]

    }
  }
}
</script>