<template>
  <v-card class="auth-card">
    <v-card-title
      class="d-flex justify-center auth-card__title px-7 pt-5 pb-0 mb-3"
    >
      Регистрация
    </v-card-title>
    <v-card-text class="py-0">
      <!-- Email -->
      <div class="mb-4">
        <v-subheader class="auth-card--text px-0 mb-2"> E-mail: </v-subheader>
        <v-text-field
          solo
          height="38"
          placeholder="Введите e-mail"
          required
        ></v-text-field>
      </div>
      <!-- Password -->
      <div class="mb-4">
        <v-subheader class="auth-card--text px-0 mb-2"> Пароль: </v-subheader>
        <v-text-field
          solo
          placeholder="Введите пароль"
          height="38"
          type="password"
          required
        ></v-text-field>
      </div>
      <!-- Repeat Password -->
      <div class="mb-4">
        <v-subheader class="auth-card--text px-0 mb-2"> Подтвердите пароль: </v-subheader>
        <v-text-field
          solo
          placeholder="Повторите пароль"
          height="38"
          type="password"
          required
        ></v-text-field>
      </div>

      <p class="auth-card--text size-12">** Ниже введите данные для доступа в 1С **</p>

      <!-- login 1C -->
      <div class="mb-4">
        <v-subheader class="auth-card--text px-0 mb-2"> Ваш логин в 1C: </v-subheader>
        <v-text-field
          solo
          height="38"
          placeholder="Введите логин"
          required
        ></v-text-field>
      </div>
      <!-- Password 1C -->
      <div class="mb-5">
        <v-subheader class="auth-card--text px-0 mb-2"> Пароль 1C: </v-subheader>
        <v-text-field
          solo
          placeholder="Введите пароль"
          height="38"
          type="password"
          required
        ></v-text-field>
      </div>
    </v-card-text>

    <v-card-actions class="d-flex flex-column pb-8 pt-0">
      <!-- Регистрация -->
      <v-btn
        class="auth__btn auth__btn--last"
        block
        dark
        color="#337AB7"
        height="38"
        depressed
        to="/"
      >
        Регистрация
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {

}
</script>
