<template>
  <!-- Остатки в кассах -->
  <div class="page__card-wrap">
    <!-- Router link -->
    <v-card class="page__card">
      <v-card-title class="pa-0 my-1">
        <router-link class="page__link body-1" :to="routeName">
          Остатки в кассах
        </router-link>
        <span class="caption ml-1" style="color: #666">(в грн)</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-simple-table class="panel-simple-table">
          <template v-slot:default>
            <tbody>
              <tr v-for="item in balance" :key="item.id">
                <td class="px-0">- {{ item.name }}:</td>
                <td class="px-0 text-right pl-2">
                  {{ item.sum.toFixed(2).split(".").join(",") }}
                </td>
              </tr>
              <tr>
                <td class="px-0 text-right" colspan="2">
                  <span class="d-flex total-cell font-weight-bold pb-0">
                    Итого:
                    <span class="ml-auto">
                      {{
                        balance
                          .reduce((acum, item) => acum + item.sum, 0)
                          .toFixed(2)
                          .split(".")
                          .join(",")
                      }}
                      грн
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    routeName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      // остатки в кассах
      balance: [
        {
          id: 0,
          name: 'Янтарная',
          sum: 15000
        }, {
          id: 1,
          name: 'Внутренняя',
          sum: 50000
        }, {
          id: 2,
          name: 'Дон шоссе',
          sum: 70000
        }, {
          id: 3,
          name: 'Хенкель',
          sum: 7000
        }, {
          id: 4,
          name: 'Интернет-магазин',
          sum: 5000
        }, {
          id: 5,
          name: 'Строй24',
          sum: 150000
        }, {
          id: 6,
          name: 'Запорожье',
          sum: 50000
        }, {
          id: 7,
          name: 'Янтарная',
          sum: 70000
        }, {
          id: 8,
          name: 'Мелитополь',
          sum: 5000
        }, {
          id: 9,
          name: 'Бердянск',
          sum: 7000
        }, {
          id: 10,
          name: 'Камыная',
          sum: 1000
        }
      ],
    }
  }

}
</script>